// FileHandle API declarations see https://developer.mozilla.org/en-US/docs/Web/API/File_Handle_API 
// and https://searchfox.org/mozilla-central/source/dom/indexedDB/IDBMutableFile.h
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
// End FileHandle API declarations
//let aggrBlob = new Blob([a, b, c, d], { type: 'application/octet-stream' })
// URL.createObjectURL(aggrBlob) and the download will be performed
const hasFileSystemAccessApi = !!navigator.storage && !!navigator.storage.getDirectory;
const hasFileHandleApi = !!window.indexedDB && !!IDBDatabase.prototype.createMutableFile;
// ArrayBuffer to String helper.
function ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
}
// String to ArrayBuffer helper.
function str2ab(str) {
    var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    var bufView = new Uint16Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}
export function saveAsFileSupported() {
    return hasFileSystemAccessApi || hasFileHandleApi;
}
export function saveAsFile(filename, data) {
    if (hasFileSystemAccessApi) {
        return saveAsFileChrome(filename, data);
    }
    else if (hasFileHandleApi) {
        return openIndexDb().then(db => saveAsFileFirefox(db, filename, data));
    }
    return Promise.reject("Browser file storage not supported");
}
export function getSavedFilesNames() {
    if (hasFileSystemAccessApi) {
        return getAllFileNamesChrome();
    }
    else if (hasFileHandleApi) {
        return openIndexDb().then(db => getAllFileNamesFirefox(db));
    }
    return Promise.reject("Browser file storage not supported");
}
export function readFile(filename) {
    if (hasFileSystemAccessApi) {
        return readFileChrome(filename);
    }
    else if (hasFileHandleApi) {
        return openIndexDb().then(db => readFileFirefox(db, filename));
    }
    return Promise.reject("Browser file storage not supported");
}
export function deleteFile(filename) {
    if (hasFileSystemAccessApi) {
        return deleteFileChrome(filename);
    }
    else if (hasFileHandleApi) {
        return openIndexDb().then(db => deleteFileFirefox(db, filename));
    }
    return Promise.reject("Browser file storage not supported");
}
function openIndexDb() {
    return new Promise((resolve, reject) => {
        const dbReq = window.indexedDB.open('fileStorageDB', 1);
        dbReq.onupgradeneeded = function () {
            this.result.createObjectStore('files');
        };
        dbReq.onsuccess = function () {
            const db = this.result;
            resolve(db);
        };
        dbReq.onerror = function () {
            // VersionError if opening fails because the actual version is higher than the requested.
            reject(this.error);
        };
        dbReq.onblocked = function () {
            alert("IndexDB blocked. Please close older browser windows with this website.");
        };
    });
}
function getAllFileNamesFirefox(db) {
    return new Promise((resolve, reject) => {
        const objectStore = db.transaction(["files"]).objectStore('files');
        const request = objectStore.getAll();
        request.onerror = function () {
            reject(this.error);
        };
        request.onsuccess = function () {
            const fileHandles = request.result;
            resolve(fileHandles.map(h => h.name));
        };
    });
}
function readFileFirefox(db, filename) {
    return new Promise((resolve, reject) => {
        const objectStore = db.transaction(["files"]).objectStore('files');
        const request = objectStore.get(filename);
        request.onerror = function () {
            reject(this.error);
        };
        request.onsuccess = function () {
            const fileHandle = request.result;
            try {
                const myFile = fileHandle.open('readonly');
                const getmeta = myFile.getMetadata();
                getmeta.onsuccess = function () {
                    const size = this.result.size;
                    myFile.location = 0;
                    const reading = myFile.readAsText(size);
                    reading.onsuccess = function () {
                        // result must be string because readAsText was called.
                        resolve(this.result);
                    };
                    reading.onerror = function () {
                        reject(this.error);
                    };
                };
            }
            catch (ex) {
                // Possible exceptions see: https://developer.mozilla.org/en-US/docs/Web/API/IDBObjectStore/put#exceptions
                reject(ex.message);
            }
        };
    });
}
function deleteFileFirefox(db, filename) {
    return new Promise((resolve, reject) => {
        const request = db.transaction(['files'], 'readwrite')
            .objectStore('files')
            .delete(filename);
        request.onsuccess = function () {
            resolve();
        };
        request.onerror = function () {
            reject(this.error);
        };
    });
}
function saveAsFileFirefox(db, filename, data) {
    return new Promise((resolve, reject) => {
        const handleReq = db.createMutableFile(filename, "plain/text"); //'application/octet-stream'
        handleReq.onsuccess = function () {
            const fileHandle = this.result;
            try {
                const file = fileHandle.open('readwrite');
                const writing = file.append(data);
                writing.onerror = function () {
                    reject(this.error);
                };
                writing.onsuccess = function () {
                    const store = db.transaction(['files'], 'readwrite').objectStore('files');
                    const storeReq = store.put(fileHandle, fileHandle.name);
                    storeReq.onsuccess = function () {
                        //resolve(storeReq.result);
                        resolve();
                    };
                    storeReq.onerror = function () {
                        reject(storeReq.error);
                    };
                };
            }
            catch (ex) {
                // Possible exceptions see: https://developer.mozilla.org/en-US/docs/Web/API/IDBObjectStore/put#exceptions
                reject(ex.message);
            }
        };
    });
}
// https://wicg.github.io/file-system-access/
// https://www.html5rocks.com/de/tutorials/file/filesystem//
// https://wicg.github.io/file-system-access/#sandboxed-filesystem
// https://web.dev/file-system-access/
// interface IStorageManager extends StorageManager {
//   // Returns the root directory of the origin private file system. Available as of Chrome 85.
//   getDirectory(): Promise<FileSystemDirectoryHandle>;
// }
// interface FileSystemDirectoryHandle {
//   getFileHandle(): FileSystemFileHandle
// }
async function saveAsFileChrome(filename, content) {
    const rootDir = await navigator.storage.getDirectory();
    const fileHandle = await rootDir.getFileHandle(filename, { create: true });
    const fileStream = await fileHandle.createWritable();
    await fileStream.write(content);
    await fileStream.close();
}
function getAllFileNamesChrome() {
    return new Promise(async (resolve, reject) => {
        var e_1, _a;
        try {
            const rootDir = await navigator.storage.getDirectory();
            const fileNames = [];
            try {
                for (var _b = __asyncValues(rootDir.entries()), _c; _c = await _b.next(), !_c.done;) {
                    let [name, handle] = _c.value;
                    fileNames.push(name);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) await _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            resolve(fileNames);
        }
        catch (ex) {
            reject(ex);
        }
    });
}
async function readFileChrome(filename) {
    const rootDir = await navigator.storage.getDirectory();
    const fileHandle = await rootDir.getFileHandle(filename, { create: false });
    const file = await fileHandle.getFile();
    return file.text();
}
async function deleteFileChrome(filename) {
    const rootDir = await navigator.storage.getDirectory();
    return await rootDir.removeEntry(filename);
}
// function onError(err) {
//   console.error(err);
// }
// function saveFileChrome() {
//   function onFs(fs) {
//     fs.root.getFile('log4.txt', {create: true, exclusive: true},
//         function(fileEntry) {
//           writeToFileChrome(fileEntry);
//         },
//         onError
//     );
//   }
//   window.webkitRequestFileSystem(window.TEMPORARY, 1024*1024 /*1MB*/, onFs, onError);
// }
// function writeToFileChrome(fileEntry) {
//   fileEntry.createWriter(function(fileWriter) {
//     fileWriter.onwriteend = function(e) {
//       console.log('Write completed.');
//     };
//     fileWriter.onerror = function(e) {
//       console.log('Write failed: ' + e.toString());
//     };
//     const blob = new Blob(["test xyz"], {type : 'plain/text'});
//     fileWriter.write(blob);
//   }, onError);
// }
// function readFileChrome() {
//   function onFs(fs) {
//     fs.root.getFile('log4.txt', {}, function(fileEntry) {
//       console.log(fileEntry);
//       // fileEntry.file(function(file) {
//       //   const reader = new FileReader();
//       //   reader.onloadend = function(e) {
//       //     const textarea = document.createElement('textarea');
//       //     textarea.textContent = this.result as string;
//       //     document.body.appendChild(textarea);
//       //   };
//       //   reader.readAsText(file);
//       // }, onError);
//     }, onError);
//   }
//   window.webkitRequestFileSystem(window.TEMPORARY, 1024*1024 /*1MB*/, onFs, onError);
// }
// window.addEventListener('load', async (event) => {
//   console.log("in load");
//   // await saveAsFile("firstfile.txt", str2ab("hello world how are you"));
//   // await getSavedFilesNames().then(names => (names as string[]).forEach(n => console.log(n)));
//   // await readFile("firstfile.txt").then(c => console.log(ab2str(c)));
//   await saveAsFileChrome("firstfile.txt", str2ab("hello world how are you"));
//   await getAllFileNamesChrome().then(names => (names as string[]).forEach(n => console.log(n)));;
//   await readFileChrome("firstfile.txt").then(c => console.log(ab2str(c)));
// }); 
